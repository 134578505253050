<template>
    <transition name="modal-fade">
        <Loading v-if="isLoading" />
        <div v-else class="modal-backdrop-component">
            <div class="upload-modal-component">
                <header>
                    <div class="close-icon" @click="close">
                        <Close />
                    </div>
                    <h2 class="header">Create a New Template</h2>
                </header>
                <section class="upload-modal-body">
                    <div class="input-name">
                        <div class="upload-txt-helper">
                            <h4 class="">Template Name</h4>
                            <Tooltip
                                msg="Give this form a name (this name will only be visible to you, not your customers)"
                            />
                        </div>
                        <input
                            type="text"
                            v-model="options.name"
                            :class="!validName ? 'input-name-error' : ' '"
                            @keyup="checkTemplateName()"
                        />
                        <small class="text-error" v-if="!validName">
                            A template with the same name already exists. Please
                            use a different name
                        </small>
                    </div>

                    <div class="upload-logo">
                        <div class="upload-txt-helper">
                            <h4>Upload your logo</h4>
                            <Tooltip
                                msg="This will take the colour scheme from a logo and apply it to the form. We recommend a logo in .PNG format with a transparent background and a minimum width of 250px"
                            />
                        </div>
                        <div class="choose-file">
                            <div v-if="image">
                                <img :src="image" />
                                <p @click="removeLogo">Remove logo</p>
                            </div>
                            <div v-else>
                                <label class="upload-label" for="upload-photo"
                                    >Choose File</label
                                >
                                <input
                                    type="file"
                                    @change="onFileChange"
                                    id="upload-photo"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <footer class="template-buttons">
                    <button
                        @click="createTemplate()"
                        class="green-button"
                        :disabled="isDisabled || options.name == ''"
                        style="width: 100%;"
                    >
                        Create
                    </button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
import Vibrant from "node-vibrant";
import Loading from "../utils/Loading";
import Tooltip from "../utils/Tooltip";
import Close from "./icons/CloseIcon";

export default {
    name: "uploadModal",
    components: {
        Loading,
        Tooltip,
        Close
    },
    props: {
        templateId: {
            type: Number,
            required: true
        },
        forms: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            options: {
                type: this.templateId,
                name: "",
                headerColor: "",
                labelColor: "",
                primaryColor: "",
                secondaryColor: "",
                colorScheme: [],
                logo: "",
                logoType: ""
            },
            image: "",
            isLoading: false,
            formsNameArray: [],
            validName: true,
            isDisabled: true
        };
    },
    watch: {
        forms(newValue, oldValue) {
            // Create an array with the existent template name
            this.formsNameArray = newValue.map(item => item.name.toLowerCase());
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        checkTemplateName() {
            // Compare the new template name with the existents
            if (
                this.formsNameArray.includes(
                    this.options.name.toLowerCase().trim()
                )
            ) {
                this.validName = false;
                this.isDisabled = true;
            } else {
                this.validName = true;
                this.isDisabled = false;
            }
        },
        createTemplate() {
            this.isLoading = true;

            if (this.image) {
                Vibrant.from(this.image)
                    .getPalette()
                    .then(palette => {
                        // Selected Colors
                        this.options.headerColor = palette.DarkVibrant.hex;
                        this.options.labelColor = palette.DarkVibrant.hex;
                        this.options.primaryColor = palette.Muted.hex;
                        this.options.secondaryColor = palette.Vibrant.hex;
                        // Color Scheme
                        this.options.colorScheme = [
                            {
                                color: [
                                    "#ffffff",
                                    palette.DarkVibrant.hex,
                                    palette.DarkVibrant.hex,
                                    palette.Muted.hex,
                                    palette.Vibrant.hex,
                                    "#000000"
                                ]
                            },
                            {
                                color: [
                                    "#ffffff",
                                    palette.DarkVibrant.hex,
                                    palette.DarkVibrant.hex,
                                    palette.Vibrant.hex,
                                    palette.Muted.hex,
                                    "#000000"
                                ]
                            },
                            {
                                color: [
                                    "#ffffff",
                                    palette.DarkMuted.hex,
                                    palette.Vibrant.hex,
                                    palette.LightVibrant.hex,
                                    palette.LightVibrant.hex,
                                    "#000000"
                                ]
                            },
                            {
                                color: [
                                    "#ffffff",
                                    palette.Muted.hex,
                                    palette.Muted.hex,
                                    palette.DarkMuted.hex,
                                    palette.DarkMuted.hex,
                                    "#000000"
                                ]
                            }
                        ];
                    })
                    .then(() => this.$emit("clicked", this.options));
            } else {
                this.options.headerColor = "#000000";
                this.options.labelColor = "#000000";
                this.options.primaryColor = "#366c81";
                this.options.secondaryColor = "#6acadd";
                (this.options.colorScheme = [
                    {
                        title: "",
                        color: [
                            "#ffffff",
                            "#000000",
                            "#000000",
                            "#000000",
                            "#000000",
                            "#000000"
                        ]
                    },
                    {
                        title: "",
                        color: [
                            "#ffffff",
                            "#000000",
                            "#000000",
                            "#366c81",
                            "#6acadd",
                            "#000000"
                        ]
                    },
                    {
                        title: "",
                        color: [
                            "#ffffff",
                            "#000000",
                            "#366c81",
                            "#366c81",
                            "#366c81",
                            "#000000"
                        ]
                    },
                    {
                        title: "",
                        color: [
                            "#ffffff",
                            "#000000",
                            "#6acadd",
                            "#6acadd",
                            "#6acadd",
                            "#000000"
                        ]
                    }
                ]),
                    this.$emit("clicked", this.options);
            }
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            this.options.logo = file;
            this.options.logoType = file.type.split("/")[1];
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = e => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        removeLogo(e) {
            this.image = "";
        }
    }
};
</script>

<style lang="scss">
@import "../../../../resources/sass/_variables.scss";

header .header {
    text-align: center;
    font-weight: 900;
    color: $darkBlue;
}

.input-name {
    width: 100%;
    height: 110px;
    margin-bottom: 40px;
    input {
        height: 50px;
        width: 100%;
        border: 1px solid $inputBorder;
        border-radius: 5px;
        padding: 10px;
        font-size: 20px;
        color: $darkBlue;
    }
}
.upload-label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 1rem;
    padding: 10px 20px;
    color: $inputBorder;
    background-color: #fff;
    border: 1px solid $inputBorder;
    border-radius: 5px;
}

.choose-file {
    text-align: center;
    // padding: 20px;
    img {
        width: 200px;
    }
    p {
        cursor: pointer;
        font-size: 0.7rem;
        color: $red;
        margin: 10px 0 0 0;
    }
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.upload-modal-component {
    background: #ffffff;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    min-height: 60%;
    max-height: 70%;
    align-self: center;
    border-radius: 10px;
    padding: 20px;
}

.upload-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    height: 100%;
    align-items: flex-start;
    font-size: 1.5rem;
    font-weight: 500;
    color: $blue;
}

.input-name,
.upload-logo {
    .upload-txt-helper {
        display: flex;
        align-items: center;
        .lc-tooltip {
            margin-bottom: 5px;
        }
    }
    h4 {
        margin-right: 5px;
        color: $darkBlue;
        font-weight: bold;
    }
    .text-error {
        margin-top: 5px;
        font-size: 14px;
        color: $red;
        display: flex;
    }
    .input-name-error {
        border-color: $red;
    }
}
.upload-logo {
    width: 100%;
    min-height: 145px;
}
input:focus-visible {
    outline: 0px;
}
.mleft20 {
    margin-left: 20px;
}
</style>
