<template>
    <transition name="modal-fade">
        <div class="modal-backdrop-component">
            <Loading v-if="isLoading" />
            <div class="code-modal-component" v-else>
                <header>
                    <div class="close-icon" @click="close">
                        <Close />
                    </div>
                    <div class="code-title">
                        <h3>How to display the form on my site?</h3>
                        <p>
                            Copy and paste one of the snippets below into the
                            website you want the form to appear. The 'Plain
                            Form' snippet will embed the form right onto your
                            webpage (as per the Form Design tab). The 'Button
                            and Popup' version will embed the button on your
                            webpage (as it appears on the Button Design tab).
                            When a user clicks it, the form will appear in a
                            popup.
                            <a target="_blank" href="/install-guide"
                                >Check our guide for more information.</a
                            >
                        </p>
                    </div>
                </header>
                <div class="code-modal-body">
                    <!-- Plain Form  -->
                    <section>
                        <h3>Plain Form</h3>
                        <div class="code-box">
                            <textarea id="plain-form-text-area">
    <!-- insert the code inside the body tag  -->
        <script type="module" id="partnerForm" form_id="{{ formId }}" url="{{
                                    url
                                }}" src="{{ url_lcembed }}"></script>
    <!-- finish here --></textarea
                            >
                        </div>
                        <button
                            class="green-button button-block"
                            @click="copyPlainFormCode"
                        >
                            Copy this Code
                        </button>
                    </section>

                    <!-- Button and Modal Form  -->
                    <section>
                        <h3>Button and Modal Template</h3>
                        <div class="code-box">
                            <textarea id="button-modal-text-area">
    <!-- insert the code inside the body tag  -->
    <script type="module" src="{{ url_lcembed_modal }}"></script> 
    <button id="lc-btn-embed" class="lc-btn-embed">{{ btnTxt }}</button>
    <div id="lc-form-modal" class="lc-modal-form">
    <div class="lc-modal-content">
    <span class="lc-modal-close">&times;</span>
    <div id="modal"></div>
    </div>
    </div>
    <script type="module" id="partnerForm" form_id="{{ formId }}" url="{{
                                    url
                                }}" modal="true" src="{{
                                    url_lcembed
                                }}"></script>
    <!-- finish here --></textarea
                            >
                        </div>
                        <button
                            class="green-button button-block"
                            @click="copyButtonModalCode"
                        >
                            Copy this Code
                        </button>
                    </section>
                </div>
                <!-- <footer class="template-buttons">
                    <button @click="close" class="cancel-button">
                        Close
                    </button>
                </footer> -->
            </div>
        </div>
    </transition>
</template>

<script>
import Loading from "../utils/Loading";
import Close from "./icons/CloseIcon";
export default {
    name: "getCodeModal",
    components: {
        Loading,
        Close
    },
    props: {
        formId: {
            type: String,
            required: true
        },
        btnTxt: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            url: process.env.MIX_BASE_URL,
            url_lcembed: process.env.MIX_URL_LCEMBED,
            url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        copyPlainFormCode() {
            const copyText = document.getElementById("plain-form-text-area");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        },
        copyButtonModalCode() {
            const copyText = document.getElementById("button-modal-text-area");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        }
    }
};
</script>

<style lang="scss">
@import "../../../../resources/sass/_variables.scss";

.code-modal-component {
    background: #fff;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    border-radius: 20px;
    padding: 20px;
    width: 50%;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
}
.code-modal-component .code-title {
    padding: 0 20px 20px 20px;
}

.code-modal-body {
    overflow-y: auto;
    section {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 20px;
        height: 100%;
        align-items: flex-start;
        // font-size: 1.5rem;
        color: $darkBlue;
        h3 {
            font-weight: 900;
        }
        button {
            margin-top: 20px;
        }
    }
}

.code-title {
    width: 100%;
    height: 100%;
    color: $darkBlue;
    h3 {
        font-weight: 900;
    }
    p {
        font-size: 1.1rem;
        line-height: 1.3em;
        margin-bottom: 0;
        a {
            font-weight: 900;
            text-decoration: underline;
            color: $darkBlue;
        }
    }
}
.code-box {
    width: 100%;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    textarea {
        font-family: Courier New, monospace;
        display: block;
        width: 100%;
        padding: 12px 12px 4px;
        border: 1px solid transparent;
        border-radius: 4px;
        font-size: 0.8rem;
        line-height: 16px;
        background-color: #80b9d121;
        resize: none;
        overflow: auto;
    }
    textarea#plain-form-text-area {
        min-height: 100px;
    }
    textarea#button-modal-text-area {
        min-height: 245px;
    }
}
</style>
