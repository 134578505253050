import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

// Modules
import auth from "./modules/auth";
import forms from "./modules/forms";
import products from "./modules/products";
import ui from "./modules/ui";
import users from "./modules/users";

Vue.use(Vuex);

axios.defaults.baseURL = window.location.origin + "/api";

export default new Vuex.Store({
    modules: {
        auth,
        forms,
        products,
        ui,
        users
    }
});
