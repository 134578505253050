<template>
    <div>
        <!-- Title 1 and 2  -->
        <div class="edit-row edit-input">
            <div class="input">
                <label for="title1">Form Title</label>
                <input
                    id="title1"
                    type="text"
                    v-model="title1"
                    @input="updateTitle1(title1)"
                />
            </div>
        </div>
        <!-- Font -->
        <div class="edit-row edit-input" id="fonts">
            <div class="input">
                <label for="title1">Font</label>
                <v-select
                    v-model="fontSelected"
                    label="family"
                    :options="orderFonts(fonts)"
                    @input="fontChanged"
                    placeholder="Select a Font"
                />
            </div>
        </div>
        <!-- Color Scheme -->
        <div class="edit-row edit-input" id="color-scheme">
            <div class="input">
                <label for="title1">Color Scheme</label>
                <div class="color-scheme-selected" style="display: flex">
                    <div
                        class="scheme-color"
                        :style="{
                            backgroundColor: colorOptionsSelected.color[0]
                        }"
                    ></div>
                    <div
                        class="scheme-color"
                        :style="{
                            backgroundColor: colorOptionsSelected.color[1]
                        }"
                    ></div>
                    <div
                        class="scheme-color"
                        :style="{
                            backgroundColor: colorOptionsSelected.color[2]
                        }"
                    ></div>
                    <div
                        class="scheme-color"
                        :style="{
                            backgroundColor: colorOptionsSelected.color[3]
                        }"
                    ></div>
                    <div
                        class="scheme-color"
                        :style="{
                            backgroundColor: colorOptionsSelected.color[4]
                        }"
                    ></div>
                    <div
                        class="scheme-color"
                        :style="{
                            backgroundColor: colorOptionsSelected.color[5]
                        }"
                    ></div>
                </div>
                <v-select
                    :options="colorOptions"
                    label="title"
                    v-model="colorOptionsSelected"
                    @input="setColorScheme"
                    :searchable="false"
                >
                    <template v-slot:option="option">
                        <div
                            class="scheme-color"
                            :style="{ backgroundColor: option.color[0] }"
                        ></div>
                        <div
                            class="scheme-color"
                            :style="{ backgroundColor: option.color[1] }"
                        ></div>
                        <div
                            class="scheme-color"
                            :style="{ backgroundColor: option.color[2] }"
                        ></div>
                        <div
                            class="scheme-color"
                            :style="{ backgroundColor: option.color[3] }"
                        ></div>
                        <div
                            class="scheme-color"
                            :style="{ backgroundColor: option.color[4] }"
                        ></div>
                        <div
                            class="scheme-color"
                            :style="{ backgroundColor: option.color[5] }"
                        ></div>
                    </template>
                </v-select>
            </div>
        </div>
        <!-- Background Color -->
        <div class="edit-row">
            <p class="title">Background color</p>
            <div
                class="toggle"
                @click="openBgColorPicker()"
                v-click-outside="closeBgColorPicker"
            >
                <div
                    class="color-picker form-bg-color"
                    :style="bgColorStyle"
                ></div>
                <chrome-picker
                    v-show="isBgColorActive"
                    v-model="bgColor"
                    @input="updateBgColor"
                ></chrome-picker>
            </div>
        </div>

        <!-- Header Color -->
        <div class="edit-row">
            <p class="title">Header color</p>
            <div
                class="toggle"
                @click="openHeaderColorPicker()"
                v-click-outside="closeHeaderColorPicker"
            >
                <div
                    class="color-picker form-header-color"
                    :style="headerColorStyle"
                ></div>
                <chrome-picker
                    v-show="isHeaderColorActive"
                    v-model="headerColor"
                    @input="updateHeaderColor"
                ></chrome-picker>
            </div>
        </div>
        <!-- Label Color -->
        <div class="edit-row">
            <p class="title">Label color</p>
            <div
                class="toggle"
                @click="openLabelColorPicker()"
                v-click-outside="closeLabelColorPicker"
            >
                <div
                    class="color-picker form-label-color"
                    :style="labelColorStyle"
                ></div>
                <chrome-picker
                    v-show="isLabelColorActive"
                    v-model="labelColor"
                    @input="updateLabelColor"
                ></chrome-picker>
            </div>
        </div>
        <!-- Primary Color -->
        <div class="edit-row">
            <p class="title">Primary color</p>
            <div
                class="toggle"
                @click="openPColorPicker()"
                v-click-outside="closePCPicker"
            >
                <div class="color-picker primary-color" :style="pcStyle"></div>
                <chrome-picker
                    v-show="isPCActive"
                    v-model="primaryColor"
                    @input="updatePC"
                ></chrome-picker>
            </div>
        </div>
        <!-- Secondary Color -->
        <div class="edit-row">
            <p class="title">Secondary color</p>
            <div
                class="toggle"
                @click="openSColorPicker()"
                v-click-outside="closeSCPicker"
            >
                <div
                    class="color-picker secondary-color"
                    :style="scStyle"
                ></div>
                <chrome-picker
                    v-show="isSCActive"
                    v-model="secondaryColor"
                    @input="updateSC"
                ></chrome-picker>
            </div>
        </div>
        <!-- Disclaimer Color -->
        <div class="edit-row">
            <p class="title">Disclaimer color</p>
            <div
                class="toggle"
                @click="openDisclaimerColorPicker()"
                v-click-outside="closeDisclaimerColorPicker"
            >
                <div
                    class="color-picker disclaimer-color"
                    :style="disclaimerColorStyle"
                ></div>
                <chrome-picker
                    v-show="isDisclaimerColorActive"
                    v-model="disclaimerColor"
                    @input="updateDisclaimerColor"
                ></chrome-picker>
            </div>
        </div>
        <!-- Input border size  -->
        <div class="edit-row range">
            <div class="title">
                <label for="borderSize">Input border size</label>
                <input
                    id="borderSize"
                    type="range"
                    min="1"
                    max="5"
                    v-model="borderSize"
                    @input="updateBorderSize(borderSize)"
                />
            </div>
            <div class="toggle">
                {{ borderSize }}
                {{ borderSize.split("px")[1] == "" ? null : "px" }}
            </div>
        </div>
        <!-- Input border radius  -->
        <div class="edit-row range">
            <div class="title">
                <label for="borderRadius">Input border radius</label>
                <input
                    id="borderRadius"
                    type="range"
                    min="0"
                    max="20"
                    step="5"
                    v-model="borderRadius"
                    @input="updateBorderRadius(borderRadius)"
                />
            </div>
            <div class="toggle">
                {{ borderRadius }}
                {{ borderRadius.split("px")[1] == "" ? null : "px" }}
            </div>
        </div>

        <!-- Show / Hide logo  -->
        <div class="edit-row" v-if="image">
            <p class="title">Display Logo</p>
            <div class="toggle">
                <toggle-button
                    v-model="showLogo"
                    @change="handleShowLogo"
                    :labels="false"
                    color="#08243b"
                    :width="50"
                    :height="30"
                />
            </div>
        </div>
        <!-- Update Logo -->
        <div class="edit-row edit-input-logo mt-3">
            <div class="upload-logo">
                <p class="title">
                    Update Logo
                    <Tooltip
                        msg="This will take the colour scheme from a logo and apply it to the form. We recommend a logo in .PNG format with a transparent background and a minimum width of 250px"
                    />
                </p>

                <div class="update-logo">
                    <div v-if="image">
                        <img :src="image" @error="imageLoadError" />
                        <p @click="removeLogo">Replace logo</p>
                    </div>
                    <div v-else>
                        <label class="upload-label" for="upload-photo"
                            >Select a logo</label
                        >
                        <input
                            type="file"
                            @change="onFileChange"
                            id="upload-photo"
                        />
                    </div>
                    <div v-if="isLogoUpdated">
                        <div
                            class="upload-label"
                            style="margin-top: 10px"
                            @click="handleUpdateColorModal"
                        >
                            Update Color Scheme
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UpdateColorModal
            v-show="isUpdateColorModalVisible"
            @handleUpdateColorModal="handleUpdateColorModal"
            @updateColorScheme="updateColorScheme()"
        />
    </div>
</template>

<script>
import { Chrome } from "vue-color";
import CancelColorButton from "../../utils/CancelColorButton";
import UpdateColorModal from "../../utils/UpdateColorModal.vue";
import Tooltip from "../../utils/Tooltip";
import vSelect from "vue-select";
import Vibrant from "node-vibrant";
import Vue from "vue";
import Options from "./Options.vue";
import { isUndefined } from "lodash";

export default {
    components: {
        "chrome-picker": Chrome,
        "v-select": vSelect,
        CancelColorButton,
        Tooltip,
        Options,
        UpdateColorModal
    },
    props: {
        styles: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            // Top 50 most popular fonts, selected from Google Fonts
            fonts: [
                { category: "sans-serif", family: "Roboto" },
                { category: "sans-serif", family: "Open Sans" },
                { category: "sans-serif", family: "Noto Sans JP" },
                { category: "sans-serif", family: "Lato" },
                { category: "sans-serif", family: "Montserrat" },
                { category: "sans-serif", family: "Source Sans Pro" },
                { category: "sans-serif", family: "Roboto Condensed" },
                { category: "sans-serif", family: "Oswald" },
                { category: "sans-serif", family: "Poppins" },
                { category: "monospace", family: "Roboto Mono" },
                { category: "sans-serif", family: "Raleway" },
                { category: "sans-serif", family: "Noto Sans" },
                { category: "sans-serif", family: "PT Sans" },
                { category: "serif", family: "Roboto Slab" },
                { category: "serif", family: "Merriweather" },
                { category: "sans-serif", family: "Ubuntu" },
                { category: "serif", family: "Playfair Display" },
                { category: "sans-serif", family: "Nunito" },
                { category: "sans-serif", family: "Open Sans Condensed" },
                { category: "serif", family: "Lora" },
                { category: "serif", family: "PT Serif" },
                { category: "sans-serif", family: "Rubik" },
                { category: "serif", family: "Noto Serif" },
                { category: "sans-serif", family: "Work Sans" },
                { category: "sans-serif", family: "Mukta" },
                { category: "sans-serif", family: "Noto Sans KR" },
                { category: "sans-serif", family: "Fira Sans" },
                { category: "sans-serif", family: "Noto Sans TC" },
                { category: "sans-serif", family: "Titillium Web" },
                { category: "sans-serif", family: "Nanum Gothic" },
                { category: "sans-serif", family: "Quicksand" },
                { category: "sans-serif", family: "Nunito Sans" },
                { category: "sans-serif", family: "Hind Siliguri" },
                { category: "sans-serif", family: "Heebo" },
                { category: "sans-serif", family: "Karla" },
                { category: "sans-serif", family: "Oxygen" },
                { category: "sans-serif", family: "Anton" },
                { category: "monospace", family: "Inconsolata" },
                { category: "monospace", family: "Source Code Pro" },
                { category: "sans-serif", family: "Arimo" },
                { category: "sans-serif", family: "Barlow" },
                { category: "sans-serif", family: "PT Sans Narrow" },
                { category: "sans-serif", family: "Dosis" },
                { category: "sans-serif", family: "Josefin Sans" },
                { category: "serif", family: "Libre Baskerville" },
                { category: "sans-serif", family: "Hind" },
                { category: "sans-serif", family: "Inter" },
                { category: "serif", family: "Crimson Text" },
                { category: "serif", family: "Bitter" }
            ],
            fontSelected: this.styles.font,

            // Color Scheme
            colorOptions: isUndefined(this.styles.colorScheme)
                ? [
                      {
                          title: "",
                          color: [
                              "#ffffff",
                              "#000000",
                              "#366c81",
                              "#366c81",
                              "#6acadd",
                              "#000000"
                          ]
                      },
                      {
                          title: "",
                          color: [
                              "#ffffff",
                              "#000000",
                              "#366c81",
                              "#366c81",
                              "#366c81",
                              "#000000"
                          ]
                      },
                      {
                          title: "",
                          color: [
                              "#ffffff",
                              "#000000",
                              "#6acadd",
                              "#6acadd",
                              "#6acadd",
                              "#000000"
                          ]
                      }
                  ]
                : [
                      {
                          title: "",
                          color: [
                              this.styles.colorScheme[0].color[0],
                              this.styles.colorScheme[0].color[1],
                              this.styles.colorScheme[0].color[2],
                              this.styles.colorScheme[0].color[3],
                              this.styles.colorScheme[0].color[4],
                              this.styles.colorScheme[0].color[5]
                          ]
                      },
                      {
                          title: "",
                          color: [
                              this.styles.colorScheme[1].color[0],
                              this.styles.colorScheme[1].color[1],
                              this.styles.colorScheme[1].color[2],
                              this.styles.colorScheme[1].color[3],
                              this.styles.colorScheme[1].color[4],
                              this.styles.colorScheme[1].color[5]
                          ]
                      },
                      {
                          title: "",
                          color: [
                              this.styles.colorScheme[2].color[0],
                              this.styles.colorScheme[2].color[1],
                              this.styles.colorScheme[2].color[2],
                              this.styles.colorScheme[2].color[3],
                              this.styles.colorScheme[2].color[4],
                              this.styles.colorScheme[2].color[5]
                          ]
                      },
                      {
                          title: "",
                          color: [
                              this.styles.colorScheme[3].color[0],
                              this.styles.colorScheme[3].color[1],
                              this.styles.colorScheme[3].color[2],
                              this.styles.colorScheme[3].color[3],
                              this.styles.colorScheme[3].color[4],
                              this.styles.colorScheme[3].color[5]
                          ]
                      }
                  ],
            colorOptionsSelected: {
                title: "",
                color: [
                    this.styles.bgColor,
                    this.styles.headerColor,
                    this.styles.labelColor,
                    this.styles.primaryColor,
                    this.styles.secondaryColor,
                    this.styles.disclaimerColor
                ]
            },
            //bgColor data
            isBgColorActive: false,
            bgColorCancelButton: false,
            bgColor: {
                hex: this.styles.bgColor
            },
            bgOriginalColor: {
                hex: this.styles.bgColor
            },
            //headerColor data
            isHeaderColorActive: false,
            headerColorCancelButton: false,
            headerColor: {
                hex: this.styles.headerColor
            },
            headerOriginalColor: {
                hex: this.styles.headerColor
            },
            //labelColor data
            isLabelColorActive: false,
            labelColorCancelButton: false,
            labelColor: {
                hex: this.styles.labelColor
            },
            labelOriginalColor: {
                hex: this.styles.labelColor
            },
            //primary color data
            isPCActive: false,
            primaryColorCancelButton: false,
            primaryColor: {
                hex: this.styles.primaryColor
            },
            primaryOriginalColor: {
                hex: this.styles.primaryColor
            },
            //secondary color data
            isSCActive: false,
            secondaryColorCancelButton: false,
            secondaryColor: {
                hex: this.styles.secondaryColor
            },
            secondaryOriginalColor: {
                hex: this.styles.secondaryColor
            },
            //disclaimerColor data
            isDisclaimerColorActive: false,
            disclaimerColorCancelButton: false,
            disclaimerColor: {
                hex: this.styles.disclaimerColor
            },
            disclaimerOriginalColor: {
                hex: this.styles.disclaimerColor
            },
            // Input + Button Border
            borderSize: this.styles.borderSize.split("px")[0],
            borderRadius: this.styles.borderRadius.split("px")[0],
            // Title
            title1: this.styles.title1,
            // Partner Logo
            showLogo: this.styles.showLogo,
            image: this.styles.logoUrl,
            // Action data
            isLogoUpdated: false,
            newLogo: "",
            isUpdateColorModalVisible: false
        };
    },
    watch: {
        // In case Reset button is clicked, move the styles to original
        styles(newValue, oldValue) {
            this.fontSelected = newValue.font;
            this.bgColor.hex = newValue.bgColor;
            this.headerColor.hex = newValue.headerColor;
            this.labelColor.hex = newValue.labelColor;
            this.primaryColor.hex = newValue.primaryColor;
            this.secondaryColor.hex = newValue.secondaryColor;
            this.disclaimerColor.hex = newValue.disclaimerColor;
            this.borderSize = newValue.borderSize.split("px")[0];
            this.borderRadius = newValue.borderRadius.split("px")[0];
            this.title1 = newValue.title1;
            this.image = newValue.logoUrl;
            // Update color scheme
            this.colorOptionsSelected.color[0] = newValue.bgColor;
            this.colorOptionsSelected.color[1] = newValue.headerColor;
            this.colorOptionsSelected.color[2] = newValue.labelColor;
            this.colorOptionsSelected.color[3] = newValue.primaryColor;
            this.colorOptionsSelected.color[4] = newValue.secondaryColor;
            this.colorOptionsSelected.color[5] = newValue.disclaimerColor;
            // call fontChanged to move the font back to original
            this.fontChanged();
        }
    },
    methods: {
        fontChanged: function() {
            this.styles.font = this.fontSelected;
            document.getElementsByClassName(
                "lc-container"
            )[0].style.fontFamily = `'${this.fontSelected.family}',${this.fontSelected.category}`;
        },
        // Update colors based on color scheme
        setColorScheme: function(value) {
            // bgColor
            this.bgColor.hex = value.color[0];
            let schemeBgColor = { hex: value.color[0] };
            this.updateBgColor(schemeBgColor);
            // headerColor
            this.headerColor.hex = value.color[1];
            let schemeHeaderColor = { hex: value.color[1] };
            this.updateHeaderColor(schemeHeaderColor);
            // labelColor
            this.labelColor.hex = value.color[2];
            let schemeLabelColor = { hex: value.color[2] };
            this.updateLabelColor(schemeLabelColor);
            // primaryColor
            this.primaryColor.hex = value.color[3];
            let schemePrimaryColor = { hex: value.color[3] };
            this.updatePC(schemePrimaryColor);
            // secondaryColor
            this.secondaryColor.hex = value.color[4];
            let schemeSecondaryColor = { hex: value.color[4] };
            this.updateSC(schemeSecondaryColor);
            // disclaimerColor
            this.disclaimerColor.hex = value.color[5];
            let schemeDisclaimerColor = { hex: value.color[5] };
            this.updateDisclaimerColor(schemeDisclaimerColor);
        },
        // Add Cancel button for color picker
        mountCancelButton: function(btnIndex, btnAction) {
            var ComponentClass = Vue.extend(CancelColorButton);
            var instance = new ComponentClass({
                propsData: { action: btnAction }
            });
            instance.$mount();

            let pickerBody = document.getElementsByClassName("vc-chrome-body")[
                btnIndex
            ];
            pickerBody.appendChild(instance.$el);
        },
        //bgColor actions
        openBgColorPicker: function() {
            if (!this.isBgColorActive) {
                this.isBgColorActive = true;

                if (!this.bgColorCancelButton) {
                    this.bgColorCancelButton = true;
                    this.mountCancelButton(0, this.cancelBgColor);
                }
            }
        },
        cancelBgColor: function() {
            this.bgColor = this.bgOriginalColor.hex;
            this.styles.bgColor = this.bgOriginalColor.hex;
        },
        closeBgColorPicker: function() {
            this.isBgColorActive = false;
        },
        updateBgColor(value) {
            this.styles.bgColor = value.hex;
            // Update color scheme
            this.colorOptionsSelected.color[0] = value.hex;
        },
        //headerColor actions
        openHeaderColorPicker: function() {
            if (!this.isHeaderColorActive) {
                this.isHeaderColorActive = true;

                if (!this.headerColorCancelButton) {
                    this.headerColorCancelButton = true;
                    this.mountCancelButton(1, this.cancelHeaderColor);
                }
            }
        },
        cancelHeaderColor: function() {
            this.headerColor = this.headerOriginalColor.hex;
            this.styles.headerColor = this.headerOriginalColor.hex;
        },
        closeHeaderColorPicker: function() {
            this.isHeaderColorActive = false;
        },
        updateHeaderColor(value) {
            this.styles.headerColor = value.hex;
            // Update color scheme
            this.colorOptionsSelected.color[1] = value.hex;
        },
        //labelColor actions
        openLabelColorPicker: function() {
            if (!this.isLabelColorActive) {
                this.isLabelColorActive = true;

                if (!this.labelColorCancelButton) {
                    this.labelColorCancelButton = true;
                    this.mountCancelButton(2, this.cancelLabelColor);
                }
            }
        },
        cancelLabelColor: function() {
            this.labelColor = this.labelOriginalColor.hex;
            this.styles.labelColor = this.labelOriginalColor.hex;
        },
        closeLabelColorPicker: function() {
            this.isLabelColorActive = false;
        },
        updateLabelColor(value) {
            this.styles.labelColor = value.hex;
            // Update color scheme
            this.colorOptionsSelected.color[2] = value.hex;
        },
        //primaryColor actions
        openPColorPicker: function() {
            if (!this.isPCActive) {
                this.isPCActive = true;

                if (!this.primaryColorCancelButton) {
                    this.primaryColorCancelButton = true;
                    this.mountCancelButton(3, this.cancelPrimaryColor);
                }
            }
        },
        cancelPrimaryColor: function() {
            this.primaryColor = this.primaryOriginalColor.hex;
            this.styles.primaryColor = this.primaryOriginalColor.hex;
        },
        closePCPicker: function() {
            this.isPCActive = false;
        },
        updatePC(value) {
            this.styles.primaryColor = value.hex;
            // Update color scheme
            this.colorOptionsSelected.color[3] = value.hex;
        },
        //secondaryColor actions
        openSColorPicker: function() {
            if (!this.isSCActive) {
                this.isSCActive = true;

                if (!this.secondaryColorCancelButton) {
                    this.secondaryColorCancelButton = true;
                    this.mountCancelButton(4, this.cancelSecondaryColor);
                }
            }
        },
        cancelSecondaryColor: function() {
            this.secondaryColor = this.secondaryOriginalColor.hex;
            this.styles.secondaryColor = this.secondaryOriginalColor.hex;
        },
        closeSCPicker: function() {
            this.isSCActive = false;
        },
        updateSC(value) {
            this.styles.secondaryColor = value.hex;
            // Update color scheme
            this.colorOptionsSelected.color[4] = value.hex;
        },
        //disclaimerColor actions
        openDisclaimerColorPicker: function() {
            if (!this.isDisclaimerColorActive) {
                this.isDisclaimerColorActive = true;

                if (!this.disclaimerColorCancelButton) {
                    this.disclaimerColorCancelButton = true;
                    this.mountCancelButton(5, this.cancelDisclaimerColor);
                }
            }
        },
        cancelDisclaimerColor: function() {
            this.disclaimerColor = this.disclaimerOriginalColor.hex;
            this.styles.disclaimerColor = this.disclaimerOriginalColor.hex;
        },
        closeDisclaimerColorPicker: function() {
            this.isDisclaimerColorActive = false;
        },
        updateDisclaimerColor(value) {
            this.styles.disclaimerColor = value.hex;
            // Update color scheme
            this.colorOptionsSelected.color[5] = value.hex;
        },
        //border action
        updateBorderSize(value) {
            this.styles.borderSize = value + "px";
        },
        updateBorderRadius(value) {
            this.styles.borderRadius = value + "px";
        },
        //titles action
        updateTitle1(value) {
            this.styles.title1 = value;
        },
        //logo actions
        removeLogo: function(e) {
            this.image = "";
        },
        imageLoadError() {},
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            // Add new logo file and name to styles to be updated on S3
            var file = files[0];
            this.styles.logo = file;
            this.styles.logoType = file.type.split("/")[1];
            this.styles.logoUrl = `https://${process.env.MIX_DO_SPACES_BUCKET}.nyc3.digitaloceanspaces.com/templates/${this.styles.formId}/${this.styles.formId}.${this.styles.logoType}`;

            // Generate the image to display on the Update logo section
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = e => {
                vm.image = e.target.result;
                vm.newLogo = e.target.result;
            };
            reader.readAsDataURL(file);
            this.$store
                .dispatch("forms/updateStyle", this.styles)
                .then(response => {
                    if (response == "success") {
                        // Get the logo url and refresh
                        let logo = document.getElementById("logo-template");
                        if (logo) {
                            logo.src = this.styles.logoUrl;
                        }
                        this.image = this.styles.logoUrl;
                        this.isLogoUpdated = true;
                    }
                });
        },
        updateColorScheme() {
            Vibrant.from(this.newLogo)
                .getPalette()
                .then(palette => {
                    // Update colors selected
                    let newColors = {
                        color: [
                            "#ffffff",
                            palette.DarkVibrant.hex,
                            palette.DarkVibrant.hex,
                            palette.Muted.hex,
                            palette.Vibrant.hex,
                            "#000000"
                        ]
                    };
                    this.setColorScheme(newColors);
                    // Deactivate the Update Color scheme button
                    this.isLogoUpdated = false;
                    // Close Update Color Modal
                    this.handleUpdateColorModal();
                });
        },
        handleUpdateColorModal() {
            this.isUpdateColorModalVisible = !this.isUpdateColorModalVisible;
        },
        handleShowLogo() {
            this.styles.showLogo = this.showLogo;
        },
        orderFonts(fonts) {
            return fonts.slice().sort(function(a, b) {
                if (a.family < b.family) {
                    return -1;
                }
                if (a.family > b.family) {
                    return 1;
                }
            });
        }
    },
    computed: {
        bgColorStyle: function() {
            return {
                "--bg-color": this.bgColor.hex
            };
        },
        headerColorStyle: function() {
            return {
                "--header-color": this.headerColor.hex
            };
        },
        labelColorStyle: function() {
            return {
                "--label-color": this.labelColor.hex
            };
        },
        pcStyle: function() {
            return {
                "--primary-color": this.primaryColor.hex
            };
        },
        scStyle: function() {
            return {
                "--secondary-color": this.secondaryColor.hex
            };
        },
        disclaimerColorStyle: function() {
            return {
                "--disclaimer-color": this.disclaimerColor.hex
            };
        }
    }
};
</script>

<style lang="scss">
.update-logo {
    text-align: center;
    padding: 10px 0;
    img {
        max-height: 80px;
        max-width: 250px;
    }
    p {
        cursor: pointer;
        font-size: 0.7rem;
        color: #e3342f;
        margin: 10px 0 0 0;
    }
}

.edit-row {
    .upload-logo {
        p.title {
            flex-direction: row;
            align-items: center;
            span.lc-tooltip {
                margin-left: 5px;
                margin-bottom: 0;
            }
        }
    }
}
.vc-chrome-body {
    text-align: center;
}
.lc-color-picker-cancel {
    font-family: "Avenir", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #356b81;
    padding: 10px 20px;
    margin: 25px 0 10px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}

// Design
.form-bg-color {
    background-color: var(--bg-color);
}
.form-header-color {
    background-color: var(--header-color);
}
.form-label-color {
    background-color: var(--label-color);
}
.primary-color {
    background-color: var(--primary-color);
}
.secondary-color {
    background-color: var(--secondary-color);
}
.disclaimer-color {
    background-color: var(--disclaimer-color);
}
// Color Scheme

.scheme-color {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 10px;
    border: 1px solid #ccc;
}
#color-scheme,
#fonts {
    .vs__selected-options {
        input {
            border: none;
        }
    }
    .vs__actions {
        padding: 4px 10px 0 3px;
    }
    .vs__clear {
        display: none;
    }
    .vs__selected {
        margin: 0px 2px 0px 2px;
    }
    .color-scheme-selected {
        display: flex;
        position: absolute;
        margin-top: 60px;
        margin-left: 20px;
    }
}
</style>
