<template>
  <div>
    <navbar />
    <router-view />
  </div>
</template>
<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>
