require("./bootstrap");

import Vue from "vue";
import VueRouter from "vue-router";
import ToggleButton from "vue-js-toggle-button";
import store from "./store";
import VueCurrencyInput from "vue-currency-input";
import Vuelidate from "vuelidate";
import Tooltip from "vue-directive-tooltip";

Vue.use(require("vue-moment"));
Vue.use(VueRouter);
Vue.use(ToggleButton);
Vue.use(VueCurrencyInput);
Vue.use(Vuelidate);
Vue.use(Tooltip);

// Import Route
import { routes } from "./routes";
import App from "./App.vue";
// Router system
const router = new VueRouter({
    mode: "history",
    routes
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
